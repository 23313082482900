$size-base: 1rem;
$size-xs: $size-base * 0.25;
$size-sm: $size-base * 0.5;
$size-md: $size-base * 1;
$size-lg: $size-base * 1.5;
$size-xl: $size-base * 2;
$size-2xl: $size-base * 2.5;
$size-3xl: $size-base * 3;

// From Tailwind
$break-sm: 576px;
$break-md: 768px;
$break-lg: 1024px;
$break-xl: 1280px;
$break-2xl: 1600px;
