.c-ion-popover {
  --height: 78px;
  --width: 120px;
  --offset-y: -32px;

  &::part(content) {
    border-radius: 12px;
    justify-content: center;
    color: var(--color-blue-500);
    align-items: center;
  }
}
