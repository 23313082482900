html,
body {
  font-size: 16px;
  line-height: 1.4;
}

* {
  font-family: Inter, sans-serif;
  box-sizing: border-box;
}
