@import "../sizing.scss";
@import "../variables.scss";

.c-field {
  margin-bottom: $size-lg;

  &__label {
    margin-bottom: $size-sm;
    font-size: $size-md;
    line-height: normal;
    color: var(--color-blue-700);
  }

  &__input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__input,
  &__select {
    --highlight-color: transparent !important;
    --placeholder-color: var(--color-gray-500);
    --color: var(--color-black);
    background: var(--color-blue-100);
    border: none;
    min-height: unset !important;
    border-radius: 8px;
    padding: $size-base !important;

    &:disabled,
    &--disabled {
      background: var(--color-gray-100);
      --placeholder-color: var(--color-gray-400);
    }

    &--invalid,
    &.ion-touched.ion-invalid {
      background: var(--color-orange-100);
      --placeholder-color: var(--color-orange-300);
    }

    .native-input {
      caret-color: var(--color-blue-700) !important;
    }

    /*
    * The styles below prevent the autofill from changing the background and text color of the input.
    */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-background-clip: text !important;
      -webkit-text-fill-color: var(--color) !important;
    }
  }

  &__icon {
    position: absolute;
    z-index: 2;
    right: $size-md;
    color: var(--color-blue-700);
    font-size: 1.5 * $size-base;

    &--error {
      color: var(--color-orange-300) !important;
    }

    &--left {
      left: $size-md;
      right: auto;
    }
  }

  &__error {
    z-index: 2;
    display: inline-block;
    margin-top: $size-xs;
    color: var(--color-orange-300);
    font-size: 0.9 * $size-base;
  }

  @media screen and (min-width: $break-md) {
    &__label {
      font-size: 1.125 * $size-base;
    }

    &__input {
      font-size: $size-base;
    }
  }
}
