@import "../sizing.scss";
@import "../variables.scss";

.c-btn {
  --button-background: var(--color-primary-500);
  --button-color: white;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: $size-base;
  border-radius: 0.5 * $size-base;
  max-height: 48px;
  background: var(--button-background);
  border: 0;
  appearance: none;

  color: var(--button-color);
  font-size: $size-base;
  letter-spacing: 1px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  width: 100%;

  cursor: pointer;

  &--lg {
    font-size: 1.125 * $size-base;
  }

  &--icon {
    height: 40px;
    width: 40px;
    padding: 0;
    align-items: center;
    justify-content: center;
  }

  &--inverted {
    color: var(--button-background);
    background: white;
  }

  &--blue {
    background: var(--color-blue-500);
  }

  &--gray {
    background: var(--color-blue-100);
    color: var(--color-black);
  }

  &--clear {
    background: transparent;
    border: 0;
    color: var(--button-background);
  }

  &:hover {
    opacity: 0.95;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
  }

  &:disabled {
    pointer-events: none;
    background: var(--color-gray-200);
    color: var(--color-gray-600);
  }

  &__loading {
    height: $size-lg;
    margin: -$size-sm;
    margin-left: -$size-md;
    margin-right: $size-sm;
    width: $size-lg;

    ion-spinner {
      width: 100%;
      height: 100%;

      color: var(--color-white);
    }

    &--disabled {
      ion-spinner {
        color: var(--color-gray-600);
      }
    }
  }

  &__icon {
    height: $size-lg;
    width: $size-lg;
    font-size: 1.2em;
  }

  &__arrow {
    font-size: 1.2em;
    margin: -$size-sm;
    margin-left: $size-sm;
  }

  @media screen and (min-width: $break-md) {
    font-size: 1.25 * $size-base;
  }
}
