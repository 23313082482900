@import "../sizing.scss";

.c-skeleton {
  &--sm {
    font-size: 0.75 * $size-base;
  }

  &--md {
    font-size: $size-base;
  }

  &--lg {
    font-size: $size-lg;
  }

  &--w-20 {
    width: 20%;
  }

  &--w-40 {
    width: 40%;
  }

  &--w-60 {
    width: 60%;
  }

  &--circle {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    flex-shrink: 0;

    &--full {
      width: 100%;
      height: 100%;
    }
  }
}
