@import '../sizing.scss';
@import '../variables.scss';

.c-card {
  --icon-color: var(--color-primary-500);
  --background-color: white;
  --background-image: '';

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  max-width: 24 * $size-base;
  padding: 1.5 * $size-base;

  background-color: var(--background-color);
  background-image: var(--background-image);
  background-repeat: no-repeat;
  background-position: bottom right;
  border-radius: $size-sm;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);

  &--center {
    align-items: center;
    text-align: center;

    .c-card__title,
    .c-card__description {
      max-width: none;
    }
  }

  &--illustrated {
    .c-card__title,
    .c-card__description {
      max-width: 66%;
    }
  }

  &--inverted {
    .c-card__title,
    .c-card__description {
      color: white;
    }
  }

  &__icon {
    margin-bottom: $size-sm;
    color: var(--icon-color);
    font-size: 3 * $size-base;
  }

  &__title {
    font-size: 1.4 * $size-base;
  }

  &__description {
    opacity: 0.7;
  }

  &__image {
    margin-bottom: $size-md;
  }

  &__button {
    margin-top: $size-md;
  }
}
