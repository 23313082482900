@import "../sizing.scss";
@import "../variables.scss";

.c-toast-container {
  position: fixed;
  top: $size-md;
  left: 0;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  width: 100%;

  pointer-events: none;

  &__wrapper {
    display: flex;
    justify-content: center;
  }
}

.c-toast {
  @keyframes fadeInSlideIn {
    from {
      opacity: 0;
      transform: translateY(-100%);
    }
    to {
      opacity: 1;
      transform: none;
    }
  }

  @keyframes fadeOutSlideOut {
    from {
      opacity: 1;
      transform: none;
    }
    to {
      opacity: 0;
      transform: translateY(-100%);
    }
  }

  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 46 * $size-base;

  margin-bottom: $size-md;
  padding: $size-md $size-lg;
  padding-left: $size-md;

  border-radius: $size-sm;
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  color: var(--color-gray-900);
  animation: fadeInSlideIn var(--toast-message-duration) forwards 1
    cubic-bezier(0.075, 0.82, 0.165, 1);

  &--hidden {
    animation: fadeOutSlideOut var(--toast-message-duration) forwards 1
      cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &--success {
    background: var(--color-success);
    color: white;
  }

  &--info {
    .c-toast__icon {
      color: var(--color-message);
    }
  }

  &--error {
    background: var(--color-orange-100);
    color: var(--color-orange-300);

    .c-toast__button {
      color: var(--color-orange-300);
    }
  }

  &--warning {
    background: var(--color-warning);
    color: var(--color-gray-900);
  }

  &--success,
  &--error,
  &--warning {
    .c-toast__icon {
      color: inherit;
    }
  }

  &__icon {
    flex: 1 0 auto;
    margin-right: 10px;
    color: var(--color-blue-700);
    font-size: 1.125 * $size-base;
    text-align: center;
  }

  &__label,
  &__button {
    font-size: 18px;
    line-height: normal;
    text-align: center;
  }

  &__button {
    background: none;
    border: none;
    box-shadow: none;
    margin-left: 10px;
    outline: none;
    text-decoration: underline;
    pointer-events: initial;
    height: $size-lg;

    ion-icon {
      font-size: $size-lg;
    }
  }
}
