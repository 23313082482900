@import "../sizing.scss";
@import "../variables.scss";

@keyframes overflowTrigger {
  from {
    overflow: hidden;
  }
  to {
    overflow: auto;
    overflow-x: hidden;
  }
}

ion-modal::part(backdrop) {
  z-index: 100;
  --backdrop-opacity: 0.4;
}

ion-modal::part(content) {
  --width: calc(100% - #{$size-xl});
  --height: calc(100% - #{$size-xl});
  --max-height: 84vh;
  --border-radius: $size-md;
  overflow-y: auto;
  z-index: 101;
}

.c-ion-modal {
  overflow: auto;
  position: fixed;

  &--blur {
    animation: blur-in 0.2s ease-in-out;
    animation-fill-mode: forwards;
  }

  .ion-page {
    position: relative;
    contain: content !important;
  }

  &::part(content) {
    margin: 0px 32px;
    --border-radius: #{$size-md};
    --height: auto;
    --width: 640px;
    --max-height: calc(100% - #{$size-xl});
    --max-width: 448px !important;
  }

  &--full::part(content) {
    --width: 100vw;
    --height: 100vh;
    --max-height: 100vh;
    --border-radius: 0;
    margin-top: 0;
    margin-bottom: 0;
    z-index: 101;
  }

  &--auto {
    &::part(content) {
      --border-radius: #{$size-md};
      --height: auto !important;
      --width: auto !important;
    }
  }

  scrollbar-color: var(--color-gray-300) var(--color-gray-900);
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: $size-base;
  }

  &::-webkit-scrollbar-track {
    background: var(--color-gray-900);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-gray-300);
  }

  &__search {
    --background: transparent !important;

    &::part(content) {
      --min-height: 100%;
      --max-width: 1268px !important;
      --width: 100% !important;
      display: flex;
      justify-content: center;
      margin: 0px;
    }

    @media screen and (min-width: $break-md) {
      --min-height: 430px;
      &::part(content) {
        align-items: center;
      }
    }
  }
}

.c-modal {
  background: var(--color-gray-1000);
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: $size-lg;
  position: relative;
  gap: $size-xl;

  &-backdrop__close {
    position: absolute;
    z-index: 1;
    top: $size-md;
    right: $size-md;

    @media screen and (min-width: $break-sm) {
      top: $size-lg;
      right: $size-lg;
    }

    @media screen and (min-width: $break-xl) {
      top: $size-xl;
      right: $size-xl;
    }
  }

  &__search {
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    padding: 0;

    &-input {
      --background: rgba(0, 0, 0, 0.3);
      --color: var(--color-white);
      --clear-button-color: var(--color-white);
      height: 56px;
      padding: 0;
      align-items: flex-start;
      max-width: 200px;

      .searchbar-input {
        padding: $size-base;
        padding-inline-start: $size-base !important;
        height: 56px;
      }

      .searchbar-clear-button {
        height: 56px;
      }

      .searchbar-search-icon {
        display: none;
      }
    }

    &-empty {
      margin-top: $size-lg;
      font-size: $size-lg;
      color: var(--color-white);
      line-height: 1.5;
      padding: $size-base;
      border-radius: 12px;
      background-color: rgba(0, 0, 0, 0.3);
    }

    &-content {
      display: grid;
      gap: $size-base;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      align-content: flex-start;
      margin-top: $size-xl;
      max-height: calc(100vh - 175px);
      overflow: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        display: none;
      }

      @media screen and (min-width: $break-sm) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media screen and (min-width: $break-md) {
        grid-template-columns: repeat(4, 1fr);
        gap: $size-lg;
        margin-top: 0;
        max-height: calc(100vh - 168px);
      }

      @media screen and (min-width: $break-lg) {
        grid-template-columns: repeat(5, 1fr);
      }

      @media screen and (min-width: $break-xl) {
        grid-template-columns: repeat(6, 1fr);
      }
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    width: 30px;
    height: 30px;
    margin: 12px 0;
    color: var(--color-white);
    padding: 8px;
    border-radius: 50%;
    background-color: var(--color-blue-400);

    &--error {
      background-color: var(--color-orange-200);
    }

    &--warning {
      background-color: var(--color-yellow-200);
    }
  }

  &__title {
    margin-bottom: $size-sm;
  }

  &__content {
    font-size: $size-base;
    margin-bottom: 0;
    text-align: center;
    white-space: pre-wrap;
  }

  &__footer {
    display: flex;
    align-items: center;
    gap: $size-xl;
    width: 100%;
  }

  @media screen and (min-width: $break-md) {
    &__search {
      align-items: center;
    }

    &__icon {
      width: 40px;
      height: 40px;
      padding: 12px;
    }

    &__content {
      font-size: $size-lg;
    }
  }
}

@keyframes blur-in {
  from {
    backdrop-filter: blur(0);
  }
  to {
    backdrop-filter: blur(15px);
  }
}

/*
@media (max-width: 600px) {
  .c-ion-modal {
    .modal-wrapper {
      .ion-page {
        padding-top: $size-xl * 2;
        padding-bottom: 0;
        justify-content: flex-end;
      }
    }
  }

  .c-modal {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    min-height: 60vh;
    margin-bottom: 0;

    &__footer {
      border-top: 0;
    }
  }
}
*/
