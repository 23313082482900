@import "sizing.scss";

.l-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1268px;
  height: 100%;
  margin: 0 auto;
  gap: $size-md;
  padding: 0 $size-lg;
  justify-content: flex-start;

  &--scroll {
    padding-right: calc($size-base * 0.75);
    padding-left: calc($size-base * 1.125);
  }

  &--center {
    justify-content: center;
    align-items: center;
  }

  &__side {
    min-width: 100%;
    padding: 12px 12px 0 6px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 20px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-gray-400);
      border-radius: 20px;
      border: 6px solid transparent;
      background-clip: content-box;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: var(--color-gray-600);
    }
  }

  &__main {
    flex-grow: 1;
    margin-bottom: calc($size-lg + env(safe-area-inset-bottom));
  }

  @media screen and (min-width: $break-md) {
    flex-direction: row;
    justify-content: center;
    gap: $size-lg;
    height: 100%;

    &__side {
      min-width: 346px;
      max-height: 100%;
      padding: 12px 12px 0 6px;
      overflow: auto;

      &--large {
        min-width: unset;
      }
    }

    &__main {
      margin-top: 12px;
    }
  }
}

.l-layout {
  display: flex;
  flex-direction: column;

  &::part(background) {
    background:
      url("../assets/images/illustration/background-decoration.png") repeat-x
        bottom,
      var(--color-blue-background) !important;
  }
}
